<template>
  <fragment>
    <product/>
    <popular-products/>
  </fragment>
</template>

<script>
import Product from "@/components/catalog/Product";
import PopularProducts from "@/components/catalog/PopularProducts.vue";
import {mapActions} from "vuex";


export default {
  name: "ProductCard",
  async mounted() {
    await this.fetchPopularProducts();
  },
  components: {
    Product,
    PopularProducts
  },
  methods: {
    ...mapActions('main_page', ['fetchPopularProducts'])
  }
}
</script>

<style scoped>

</style>