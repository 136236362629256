<template>
  <div class="container popular_section" v-if="isCurrentUserDealer">
    <h2 class="title"><span>Популярные товары</span></h2>
    <div class="popular-products row d-flex">
      <div class="popular-products__item col-lg-3 col-md-4 col-sm-6 col-12" v-for="(product, key) in popularProducts" :key="key">
        <product-item :product="product" ></product-item>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductItem from "./ProductItem";

export default {
  name: "PopularProducts",
  components: {
    ProductItem
  },
  computed: {
    ...mapGetters('main_page', ['popularProducts'])
  }
}
</script>

<style lang="sass" scoped>
@media (max-width: 485px)
  .popular-products
    flex-direction: column
    align-items: center

    &__item:not(:last-child)
      margin-bottom: 40px
</style>